import React, { FC } from "react";
import * as styles from "../popup.module.scss";
import { muiStylesPopup } from "../muistylesPopup";
import { I18n } from "react-redux-i18n";
import { Dialog, DialogContent, DialogTitle, TextField, Box, styled } from "@mui/material";
import PrimaryButton from "../../Buttons/PrimaryButton";
import SecondaryButton from "../../Buttons/SecondaryButton";
import { commonFormBase } from "../../../../styles/mui/theme";

const ButtonsContainer = styled(Box)({
  "& .MuiButton-root": {
    marginRight: 0,
  }
});

interface TwoFactorVerifyPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onVerify: () => void;
  token: string;
  onTokenChange: (value: string) => void;
  error: string;
}

const TwoFactorVerifyPopup: FC<TwoFactorVerifyPopupProps> = ({
  isOpen,
  onClose,
  onVerify,
  token,
  onTokenChange,
  error
}) => {
  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === "escapeKeyDown") {
          onClose();
        }
      }}
      PaperProps={{
        sx: {
          ...muiStylesPopup.dialogPaper,
          maxWidth: "500px",
          width: "100%"
        }
      }}
    >
      <DialogTitle sx={muiStylesPopup.dialogHeader}>
        <div style={{ textAlign: "center" }}>
          <h1>{I18n.t("TwoFactor.verifyTitle")}</h1>
        </div>
      </DialogTitle>
      <DialogContent
        sx={{
          ...muiStylesPopup.dialogContent,
          "& .MuiButton-root": { margin: 0 }
        }}
      >
        <div role="dialog">
          <p>{I18n.t("TwoFactor.enterCode")}</p>
          <TextField
            margin="dense"
            label={I18n.t("TwoFactor.verificationCode")}
            type="text"
            fullWidth
            value={token}
            onChange={(e) => onTokenChange(e.target.value)}
            error={!!error}
            helperText={error ? I18n.t(error) : ''}
            sx={{ ...commonFormBase.input, width: "100%" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <ButtonsContainer className={styles.buttonsContainerSpaceBetween}>
          <SecondaryButton
            text={I18n.t("Buttons.cancel")}
            event={onClose}
          />
          <PrimaryButton
            text={I18n.t("Buttons.confirm")}
            event={onVerify}
          />
        </ButtonsContainer>
      </DialogContent>
    </Dialog>
  );
};

export default TwoFactorVerifyPopup;