import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { AppDispatch } from '../../../redux/store';
import PrimaryButton from '../../Common/Buttons/PrimaryButton';
import { setup2FA, verify2FASetup, disable2FA } from '../../../redux/actions/authActions';
import TwoFactorSetupPopup from '../../Common/Popup/TwoFactorSetupPopup';
import TwoFactorVerifyPopup from '../../Common/Popup/TwoFactorVerifyPopup';
import TwoFactorDisablePopup from '../../Common/Popup/TwoFactorDisablePopup';
import * as styles from './twoFactorSettings.module.scss';

interface Props {
  user: any;
}

const TwoFactorSettings: FC<Props> = ({ user }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [setupDialogOpen, setSetupDialogOpen] = useState<boolean>(false);
  const [verifyDialogOpen, setVerifyDialogOpen] = useState<boolean>(false);
  const [disableDialogOpen, setDisableDialogOpen] = useState<boolean>(false);
  const [secret, setSecret] = useState<string>('');
  const [backupCodes, setBackupCodes] = useState<string[]>([]);
  const [qrCode, setQrCode] = useState<string>('');
  const [token, setToken] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [disableCode, setDisableCode] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleSetup2FA = async () => {
    try {
      // Clear previous state
      setError('');
      setSecret('');
      setBackupCodes([]);
      setQrCode('');

      const response = await dispatch(setup2FA()).unwrap();

      if (!response.provisioningUri) {
        setError('TwoFactor.setupError');
        return;
      }

      // Set new values
      setSecret(response.secret);
      setBackupCodes(response.backupCodes || []);
      setQrCode(response.provisioningUri);
      setSetupDialogOpen(true);
    } catch (setupError) {
      setError('TwoFactor.setupError');
    }
  };

  const handleVerify2FA = async () => {
    try {
      const response = await dispatch(verify2FASetup({ token })).unwrap();
      if (response) {
        setVerifyDialogOpen(false);
        setSetupDialogOpen(false);
        // Clear state
        setToken('');
        setSecret('');
        setBackupCodes([]);
        setQrCode('');
        setError('');
      }
    } catch (err) {
      setError('TwoFactor.verificationError');
    }
  };

  const handleDisable2FA = async () => {
    try {
      const response = await dispatch(disable2FA({ password, twoFactorToken: disableCode })).unwrap();
      if (response) {
        setDisableDialogOpen(false);
        setPassword('');
        setDisableCode('');
        setError('');
      } else {
        setError('TwoFactor.disableError');
      }
    } catch (err) {
      setError('TwoFactor.disableError');
    }
  };

  const handleContinueSetup = () => {
    setSetupDialogOpen(false);
    setVerifyDialogOpen(true);
    setToken('');
  };

  if (!user) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.buttonWrapper}>
        {user.twoFactorEnabled ? (
          <PrimaryButton
            text={I18n.t('Buttons.disable2FA')}
            event={() => setDisableDialogOpen(true)}
          />
        ) : (
          <PrimaryButton
            text={I18n.t('Buttons.enable2FA')}
            event={handleSetup2FA}
          />
        )}
      </div>

      {/* Setup Dialog */}
      <TwoFactorSetupPopup
        isOpen={setupDialogOpen}
        onClose={() => setSetupDialogOpen(false)}
        onContinue={handleContinueSetup}
        secret={secret}
        backupCodes={backupCodes}
        qrCode={qrCode}
        error={error}
      />

      {/* Verify Dialog */}
      <TwoFactorVerifyPopup
        isOpen={verifyDialogOpen}
        onClose={() => setVerifyDialogOpen(false)}
        onVerify={handleVerify2FA}
        token={token}
        onTokenChange={(value) => setToken(value)}
        error={error}
      />

      {/* Disable Dialog */}
      <TwoFactorDisablePopup
        isOpen={disableDialogOpen}
        onClose={() => setDisableDialogOpen(false)}
        onDisable={handleDisable2FA}
        password={password}
        twoFactorCode={disableCode}
        onPasswordChange={(value) => setPassword(value)}
        onTwoFactorCodeChange={(value) => setDisableCode(value)}
        error={error}
      />
    </div>
  );
};

export default TwoFactorSettings;