import React, { FC, useEffect, useState } from "react";
import * as styles from "../popup.module.scss";
import { muiStylesPopup } from "../muistylesPopup";
import { I18n } from "react-redux-i18n";
import { Dialog, DialogContent, DialogTitle, Typography, Box, styled } from "@mui/material";
import PrimaryButton from "../../Buttons/PrimaryButton";
import SecondaryButton from "../../Buttons/SecondaryButton";
import client from "../../../../api/axiosConfig";
import * as setupStyles from "./twoFactorSetup.module.scss";

const ButtonsContainer = styled(Box)({
  "& .MuiButton-root": {
    marginRight: 0,
  }
});

interface TwoFactorSetupPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
  secret: string;
  backupCodes: string[];
  qrCode: string;
  error: string;
}

const TwoFactorSetupPopup: FC<TwoFactorSetupPopupProps> = ({
  isOpen,
  onClose,
  onContinue,
  secret,
  backupCodes,
  qrCode,
  error
}) => {
  const [qrDataUrl, setQrDataUrl] = useState<string>('');

  useEffect(() => {
    const generateQRCode = async () => {
      if (!qrCode) {
        return;
      }

      try {
        const response = await client.post('/api/users/generate_qr/', {
          data: qrCode,
          size: 200,
          margin: 4,
          level: 'L'
        });
        const qrCodeData = response.data?.results?.qrCode;

        if (!response.data?.results || !qrCodeData || typeof qrCodeData !== 'string' || !qrCodeData.startsWith('data:image/png;base64,')) {
          return;
        }

        setQrDataUrl(qrCodeData);
      } catch (err) {
        console.error('Failed to generate QR code:', err);
      }
    };

    void generateQRCode();
  }, [qrCode]);

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === "escapeKeyDown") {
          onClose();
        }
      }}
      PaperProps={{
        sx: {
          ...muiStylesPopup.dialogPaper,
          maxWidth: "500px",
          width: "100%"
        }
      }}
    >
      <DialogTitle sx={muiStylesPopup.dialogHeader}>
        <div style={{ textAlign: "center" }}>
          <h1>{I18n.t("TwoFactor.setupTitle")}</h1>
        </div>
      </DialogTitle>
      <DialogContent
        sx={{
          ...muiStylesPopup.dialogContent,
          "& .MuiButton-root": { margin: 0 }
        }}
      >
        <div role="dialog">
          <div className={setupStyles.qrCodeContainer}>
            {qrDataUrl ? (
              <img
                src={qrDataUrl}
                alt="2FA QR Code"
                className={setupStyles.qrCode}
              />
            ) : (
              <div className={setupStyles.qrCodeLoading}>
                {I18n.t("TwoFactor.loadingQRCode")}
              </div>
            )}
          </div>
          <Typography style={{ marginBottom: "16px" }}>
            {I18n.t("TwoFactor.scanQRCode")}
          </Typography>
          <Typography style={{ marginBottom: "16px" }}>
            {I18n.t("TwoFactor.secretKey")}: {secret}
          </Typography>
          {Array.isArray(backupCodes) && backupCodes.length > 0 && (
            <div className={setupStyles.backupCodesContainer}>
              <h4>{I18n.t("TwoFactor.backupCodes")}</h4>
              <Typography style={{ marginBottom: "16px" }}>
                {I18n.t("TwoFactor.backupCodesInfo")}
              </Typography>
              <div className={setupStyles.backupCodesList}>
                {backupCodes.map((code, index) => (
                  <code key={index} className={setupStyles.backupCode}>
                    {code}
                  </code>
                ))}
              </div>
            </div>
          )}
          {error && (
            <Typography color="error" style={{ marginTop: "16px" }}>
              {error}
            </Typography>
          )}
        </div>
        <ButtonsContainer className={styles.buttonsContainerSpaceBetween}>
          <SecondaryButton
            text={I18n.t("Buttons.cancel")}
            event={onClose}
          />
          <PrimaryButton
            text={I18n.t("Buttons.continue")}
            event={onContinue}
          />
        </ButtonsContainer>
      </DialogContent>
    </Dialog>
  );
};

export default TwoFactorSetupPopup;