import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Paper, Typography } from '@mui/material';
import TwoFactorSettings from '../../components/Settings/TwoFactorSettings';
import { RootState } from '../../redux/store'; // Adjust the import path as needed

const UserSettings: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.user); // Adjust according to your store structure

  return (
    <Container maxWidth="md">
      <Paper sx={{ p: 3, mt: 3 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Security Settings
        </Typography>
        <TwoFactorSettings user={user} />
      </Paper>
    </Container>
  );
};

export default UserSettings;