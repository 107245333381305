import React, { FC } from "react";
import * as styles from "../popup.module.scss";
import { muiStylesPopup } from "../muistylesPopup";
import { I18n } from "react-redux-i18n";
import { Dialog, DialogContent, DialogTitle, TextField, Box, styled, Typography } from "@mui/material";
import PrimaryButton from "../../Buttons/PrimaryButton";
import SecondaryButton from "../../Buttons/SecondaryButton";
import { commonFormBase } from "../../../../styles/mui/theme";

const ButtonsContainer = styled(Box)({
  "& .MuiButton-root": {
    marginRight: 0,
  }
});

interface TwoFactorDisablePopupProps {
  isOpen: boolean;
  onClose: () => void;
  onDisable: () => void;
  password: string;
  twoFactorCode: string;
  onPasswordChange: (value: string) => void;
  onTwoFactorCodeChange: (value: string) => void;
  error: string;
}

const TwoFactorDisablePopup: FC<TwoFactorDisablePopupProps> = ({
  isOpen,
  onClose,
  onDisable,
  password,
  twoFactorCode,
  onPasswordChange,
  onTwoFactorCodeChange,
  error
}) => {
  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === "escapeKeyDown") {
          onClose();
        }
      }}
      PaperProps={{
        sx: {
          ...muiStylesPopup.dialogPaper,
          maxWidth: "500px",
          width: "100%"
        }
      }}
    >
      <DialogTitle sx={muiStylesPopup.dialogHeader}>
        <div style={{ textAlign: "center" }}>
          <h1>{I18n.t("TwoFactor.disableTitle")}</h1>
        </div>
      </DialogTitle>
      <DialogContent
        sx={{
          ...muiStylesPopup.dialogContent,
          "& .MuiButton-root": { margin: 0 }
        }}
      >
        <div role="dialog">
          <Typography style={{ textAlign: "center", marginBottom: "16px" }}>
            {I18n.t("TwoFactor.confirmDisable")}
          </Typography>
          <TextField
            margin="dense"
            label={I18n.t("FormLabels.password")}
            type="password"
            fullWidth
            value={password}
            onChange={(e) => onPasswordChange(e.target.value)}
            error={!!error}
            helperText={error ? I18n.t(error) : ''}
            sx={{ ...commonFormBase.input, width: "100%", marginBottom: "16px" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            fullWidth
            value={twoFactorCode}
            onChange={(e) => onTwoFactorCodeChange(e.target.value)}
            error={!!error}
            helperText={error ? I18n.t(error) : ''}
            label={I18n.t("TwoFactor.verificationCode")}
            margin="normal"
            sx={{ ...commonFormBase.input, width: "100%" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <ButtonsContainer className={styles.buttonsContainerSpaceBetween}>
          <SecondaryButton
            text={I18n.t("Buttons.cancel")}
            event={onClose}
          />
          <PrimaryButton
            text={I18n.t("Buttons.disable2FA")}
            event={onDisable}
            disabled={!password || !twoFactorCode}
          />
        </ButtonsContainer>
      </DialogContent>
    </Dialog>
  );
};

export default TwoFactorDisablePopup;