// extracted by mini-css-extract-plugin
export var header = "twoFactorSettings-module--header--1TNp7";
export var qrCodeContainer = "twoFactorSettings-module--qrCodeContainer--kEsWX";
export var qrCode = "twoFactorSettings-module--qrCode--mfSO7";
export var qrCodeLoading = "twoFactorSettings-module--qrCodeLoading--enFru";
export var backupCodesContainer = "twoFactorSettings-module--backupCodesContainer--ACPul";
export var backupCodesList = "twoFactorSettings-module--backupCodesList--TsAQA";
export var backupCode = "twoFactorSettings-module--backupCode--avbpF";
export var container = "twoFactorSettings-module--container--StzYy";
export var actionButton = "twoFactorSettings-module--actionButton--D5zhg";
export var buttonWrapper = "twoFactorSettings-module--buttonWrapper--TXqTn";